<template>
  <div class="card p-4">
    <div class="mb-4 h4 font-weight-bold text-color-green">
      Detail Hasil Pemeriksaan & Spesimen
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <div class="card">
          <div class="card-body" style="background-color: #f6feff">
            <div class="">
              <div class="row">
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Nama
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.name }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Tanggal Lahir
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.birth_date }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Usia
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.age }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Jenis Kelamin
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.gender }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Cara Pembayaran
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.payment_method }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Dokter
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Doctor.User.name }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        No. RM
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.medical_record_no }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Status
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.status }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <a-tabs :default-active-key="setTab()">
          <a-tab-pane key="1" tab="Hasil Pemeriksaan">
            <div class="row">
              <div class="col-2">Petugas Penganalisis Spesimen</div>
              <div class="col-10">{{ pemeriksaanCreator }}</div>
            </div>
            <a-divider></a-divider>
            <div v-for="(data, idx) in masterPemeriksaan" :key="idx">
              <div>
                <h6>{{ data.name }}</h6>
                <div class="row mt-3">
                  <div class="col-3">Komponen Pemeriksaan</div>
                  <div class="col-2">Nilai Hasil</div>
                  <div class="col-2">Nilai Rujukan</div>
                  <div class="col-2">Nilai Kritis Rendah</div>
                  <div class="col-2">Nilai Kritis Tinggi</div>
                </div>
                <a-divider></a-divider>
                <div
                  class="row my-4"
                  v-for="(item, idx) in data.components"
                  :key="idx"
                >
                  <div class="col-3">{{ item.name }}</div>
                  <div class="col-2">{{ item.resultValue }}</div>
                  <template v-if="item.resultType === 'Quantitative'">
                    <div class="col-2">{{ item.referenceValue }}</div>
                    <div class="col-2">>{{ item.criticalLow }}</div>
                    <div class="col-2">>{{ item.criticalHigh }}</div>
                  </template>
                </div>
              </div>
              <a-divider></a-divider>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="Data Spesimen" force-render>
            <div class="row">
              <div class="col-2">Petugas Pengambil Spesimen</div>
              <div class="col-10">{{ spesimenCreator }}</div>
            </div>
            <a-divider></a-divider>
            <div v-for="(item, idx) in masterSpesimen" :key="idx" class="my-4">
              <h6>{{ item.name }}</h6>
              <div class="row">
                <div class="col-2">Status Spesimen</div>
                <div class="col-10">
                  {{ item.statusDisplay }}
                </div>
              </div>
              <a-divider></a-divider>
              <div class="row">
                <div class="col-2">Asal Sumber</div>
                <div class="col-10">{{ item.sourceOfOriginDisplay }}</div>
              </div>
              <div class="row mt-5">
                <div class="col-2">Lokasi Pengambilan</div>
                <div class="col-10">{{ item.locationDisplay }}</div>
              </div>
              <div class="row mt-5">
                <div class="col-2">Jumlah dan Volume</div>
                <div class="col-10">{{ item.value }} {{ item.unit }}</div>
              </div>
              <div class="row mt-5">
                <div class="col-2">Waktu Pengambilan</div>
                <div class="col-10">{{ item.time }}</div>
              </div>
              <div class="row mt-5">
                <div class="col-2">Metode Pengambilan</div>
                <div class="col-10">
                  {{ item.methodDisplay }}
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-2">Kondisi Pada Saat Pengambilan</div>
                <div class="col-10">{{ item.conditionDisplay }}</div>
              </div>
            </div>
            <a-divider></a-divider>
          </a-tab-pane>
        </a-tabs>
      </div>
      <a-modal
        v-model="modalDeleteVisible"
        title="Hapus Pemeriksaan?"
        @ok="handleDelete"
        centered
      >
        <WarningOutlined />
        <span class="ml-2">Yakin Ingin Menghapus Data?</span>
      </a-modal>
    </div>
  </div>
</template>

<script>
import {
  getExaminations,
  getExaminationsCategories,
  deleteExaminations,
  getExaminationRequestById,
} from '@/services/axios/api/pemeriksaan'
import { WarningOutlined } from '@ant-design/icons-vue'
// import { notification } from 'ant-design-vue'

import { getPatientVisit } from '@/services/axios/api/kunjungan'

import { getUserProfileFromId } from '@/services/axios/api/auth'
export default {
  components: { WarningOutlined },
  data() {
    return {
      activeKey: '1',
      name: '',
      date: null,
      filterText: '', // State for the filter input,
      tableData: [
        {
          No: 1,
          noPermintaan: 'PK1234567890',
          noRekamMedis: '1234567890',
          namaPasien: 'Muhammad Ramadan',
          tanggal: '10 Juli 2024',
          waktu: '13:30:04',
          perujuk: 'dr. Nur Fajar',
          kategori: 'BPJS Kesehatan',
          status: 'Menunggu Spesimen',
        },
        {
          No: 2,
          noPermintaan: 'PK1234567890',
          noRekamMedis: '1234567890',
          namaPasien: 'Ariel Rupawan',
          tanggal: '10 Juli 2024',
          waktu: '13:48:35',
          perujuk: 'dr. Nur Fajar',
          kategori: 'Mandiri',
          status: 'Menunggu Spesimen',
        },
        {
          No: 3,
          noPermintaan: 'PK1234567890',
          noRekamMedis: '1234567890',
          namaPasien: 'Budi Prasetyo',
          tanggal: '10 Juli 2024',
          waktu: '14:00:30',
          perujuk: 'dr. Nur Fajar',
          kategori: 'Mandiri',
          status: 'Menunggu Spesimen',
        },
        {
          No: 4,
          noPermintaan: 'PK1234567890',
          noRekamMedis: '1234567890',
          namaPasien: 'Claudia S',
          tanggal: '10 Juli 2024',
          waktu: '14:00:30',
          perujuk: 'dr. Dimas Prasetya',
          kategori: 'Asuransi lainnya',
          status: 'Menunggu Pemeriksaan',
        },
        // Data lainnya...
      ],
      filteredTableData: [
        {
          No: 1,
          noPermintaan: 'PK1234567890',
          noRekamMedis: '1234567890',
          namaPasien: 'Muhammad Ramadan',
          tanggal: '10 Juli 2024',
          waktu: '13:30:04',
          perujuk: 'dr. Nur Fajar',
          kategori: 'BPJS Kesehatan',
          status: 'Menunggu Spesimen',
        },
        {
          No: 2,
          noPermintaan: 'PK1234567890',
          noRekamMedis: '1234567890',
          namaPasien: 'Ariel Rupawan',
          tanggal: '10 Juli 2024',
          waktu: '13:48:35',
          perujuk: 'dr. Nur Fajar',
          kategori: 'Mandiri',
          status: 'Menunggu Spesimen',
        },
        {
          No: 3,
          noPermintaan: 'PK1234567890',
          noRekamMedis: '1234567890',
          namaPasien: 'Budi Prasetyo',
          tanggal: '10 Juli 2024',
          waktu: '14:00:30',
          perujuk: 'dr. Nur Fajar',
          kategori: 'Mandiri',
          status: 'Menunggu Spesimen',
        },
        {
          No: 4,
          noPermintaan: 'PK1234567890',
          noRekamMedis: '1234567890',
          namaPasien: 'Claudia S',
          tanggal: '10 Juli 2024',
          waktu: '14:00:30',
          perujuk: 'dr. Dimas Prasetya',
          kategori: 'Asuransi lainnya',
          status: 'Menunggu Pemeriksaan',
        },
        // Data lainnya...
      ],
      statusList: [
        {
          name: 'Semua',
          value: 'Semua',
        },
        {
          name: 'Menunggu Spesimen',
          value: 'Menunggu Spesimen',
        },
        {
          name: 'Menunggu Pemeriksaan',
          value: 'Menunggu Pemeriksaan',
        },
        {
          name: 'Selesai Diperiksa',
          value: 'Selesai Diperiksa',
        },
      ],
      dataSource: [],
      filteredDataSource: [],
      columns: [
        {
          title: 'No',
          dataIndex: 'No',
          key: 'no',
        },
        {
          title: 'Nomor Permintaan',
          dataIndex: 'noPermintaan',
          key: 'noPermintaan',
        },
        {
          title: 'Nomor Rekam Medis',
          dataIndex: 'noRekamMedis',
          key: 'noRekamMedis',
        },
        {
          title: 'Nama Pasien',
          dataIndex: 'namaPasien',
          key: 'namaPasien',
        },
        {
          title: 'Tanggal',
          dataIndex: 'tanggal',
          key: 'tanggal',
        },
        {
          title: 'Waktu',
          dataIndex: 'waktu',
          key: 'waktu',
        },
        {
          title: 'Perujuk',
          dataIndex: 'perujuk',
          key: 'perujuk',
        },
        {
          title: 'Kategori',
          dataIndex: 'kategori',
          key: 'kategori',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center',
        },
      ],
      categories: [],
      selectedStatus: 'Semua',
      modalDeleteVisible: false,
      selectedDeleteId: '',
      masterPemeriksaan: [],
      pemeriksaanCreator: '',
      spesimenCreator: '',
      masterSpesimen: [],
      patientVisitData: [],
    }
  },
  created() {
    this.getName()
    this.fetchData()
  },
  methods: {
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      const date = new Date(dateString)
      return date.toLocaleString('id-ID', options)
    },
    formatDateTime(dateString) {
      const date = new Date(dateString)
      const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' }
      const timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }

      const formattedDate = date
        .toLocaleDateString('id-ID', dateOptions)
        .split('/')
        .join('-')
      const formattedTime = date
        .toLocaleTimeString('id-ID', timeOptions)
        .split('.')
        .join(':')

      return `${formattedDate} ${formattedTime}`
    },
    async getUserName(id) {
      try {
        const response = await getUserProfileFromId(id)
        return response.data.user.name
      } catch (error) {
        console.error('Error fetching user name:', error)
        return 'Unknown'
      }
    },
    async getPatientVisitData(id) {
      const response = await getPatientVisit(id)
      this.patientVisitData = response.data.visit

      // console.log(this.patientVisitHistory.length, 'panjang data kunjungan history')
      // this.patientVisitHistory = this.patientVisitHistory.reverse()
      // console.log(this.patientVisitHistory, 'data kunjungan history')

      const perawatName = await this.getUserName(
        this.patientVisitData.Anamnesis.created_by,
      )

      this.patientVisitData = {
        ...this.patientVisitData,
        age: this.calculateAgeDefault(this.patientVisitData.Patient.birth_date),
        birth_date: this.formatDate(this.patientVisitData.Patient.birth_date),
        gender:
          this.patientVisitData.Patient.gender === 'Male'
            ? 'Laki-laki'
            : 'Perempuan',
        payment_method:
          this.patientVisitData.Patient.payment_method === 'JKN'
            ? 'JKN (BPJS Kesehatan)'
            : this.patientVisitData.Patient.payment_method,
        anamnesis_created_time: this.formatDateTime(
          this.patientVisitData.Anamnesis.createdAt,
        ),
        anamnesis_created_by: perawatName,
      }

      console.log(this.patientVisitData, 'data kunjungan pasien')
      // console.log(this.patientVisitDocument, 'dokumen kunjungan pasien')
    },

    getName() {
      const name = localStorage.getItem('name')
      this.name = name
    },
    calculateAge(birthDate, type) {
      const birth = new Date(birthDate)
      const now = new Date()

      if (type === 'Y') {
        // Hitung umur dalam tahun
        let age = now.getFullYear() - birth.getFullYear()
        if (
          now.getMonth() < birth.getMonth() ||
          (now.getMonth() === birth.getMonth() &&
            now.getDate() < birth.getDate())
        ) {
          age-- // Kurangi jika belum melewati ulang tahun
        }
        return age
      } else if (type === 'M') {
        // Hitung umur dalam bulan
        const years = now.getFullYear() - birth.getFullYear()
        const months = now.getMonth() - birth.getMonth()
        const totalMonths = years * 12 + months

        if (now.getDate() < birth.getDate()) {
          return totalMonths - 1 // Kurangi jika hari bulan belum dilewati
        }
        return totalMonths
      }
      return 0 // Default jika tipe tidak valid
    },
    calculateAgeDefault(birthdate) {
      const today = new Date()
      const birthDate = new Date(birthdate)

      const years = today.getFullYear() - birthDate.getFullYear()
      const months = today.getMonth() - birthDate.getMonth()
      const days = today.getDate() - birthDate.getDate()

      let ageYears = years
      let ageMonths = months
      let ageDays = days

      if (ageDays < 0) {
        const prevMonth = new Date(today.getFullYear(), today.getMonth(), 0)
        ageDays += prevMonth.getDate()
        ageMonths -= 1
      }

      if (ageMonths < 0) {
        ageMonths += 12
        ageYears -= 1
      }

      return `${ageYears} tahun, ${ageMonths} bulan, ${ageDays} hari`
    },

    setTab() {
      const role = window.localStorage.getItem('role')
      if (role === 'perawat') {
        return '1'
      } else if (role === 'dokter') {
        if (this.patientVisitData.status === 'Selesai') {
          return '2'
        } else {
          return '1'
        }
      } else {
        return '1'
      }
    },

    async fetchData() {
      try {
        const { data: dataRequest } = await getExaminationRequestById(
          this.$route.params.id,
        )
        await this.getPatientVisitData(dataRequest.data.Visit.id)

        console.log(dataRequest, 'cekini')
        const pemeriksaan = dataRequest.data.ExaminationRequestDetails.map(
          (e) => {
            let components = []
            components = e.ExaminationResults.map((el) => {
              this.pemeriksaanCreator = el.User.name
              return {
                ...el.ExaminationComponent.ExaminationIndicators[0],
                name: el.ExaminationComponent.name,
                resultValue: el.resultValue,
                resultType: el.ExaminationComponent.resultType,
              }
            })
            return {
              name: e.MasterExamination.name,
              components,
            }
          },
        )
        const spesimen = dataRequest.data.ExaminationRequestDetails.map((e) => {
          this.spesimenCreator = e.ExaminationSpesimens[0].User.name
          // console.log(e, 'initimekan?')
          return {
            ...e.ExaminationSpesimens[0],
            name: e.MasterExamination.name,
            time: this.formatDateTime(e.ExaminationSpesimens[0].time),
          }
        })
        console.log(spesimen, 'inispesimen')
        this.masterPemeriksaan = pemeriksaan
        this.masterSpesimen = spesimen
        const { data } = await getExaminations()
        const { data: dataCategories } = await getExaminationsCategories()
        this.categories = dataCategories.categories

        this.dataSource = data.pemeriksaan.map((e, idx) => ({
          ...e,
          key: idx + 1,
        }))

        this.filteredDataSource = [...this.dataSource]
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    },
    // async getPatientVisitData(id) {
    //   const response = await getPatientVisit(id)
    //   this.patientVisitData = response.data.visit

    //   // console.log(this.patientVisitHistory.length, 'panjang data kunjungan history')
    //   // this.patientVisitHistory = this.patientVisitHistory.reverse()
    //   // console.log(this.patientVisitHistory, 'data kunjungan history')

    //   const perawatName = await this.getUserName(
    //     this.patientVisitData.Anamnesis.created_by,
    //   )

    //   this.patientVisitData = {
    //     ...this.patientVisitData,
    //     age: this.calculateAge(this.patientVisitData.Patient.birth_date),
    //     birth_date: this.formatDate(this.patientVisitData.Patient.birth_date),
    //     gender:
    //       this.patientVisitData.Patient.gender === 'Male'
    //         ? 'Laki-laki'
    //         : 'Perempuan',
    //     payment_method:
    //       this.patientVisitData.Patient.payment_method === 'JKN'
    //         ? 'JKN (BPJS Kesehatan)'
    //         : this.patientVisitData.Patient.payment_method,
    //     anamnesis_created_time: this.formatDateTime(
    //       this.patientVisitData.Anamnesis.createdAt,
    //     ),
    //     anamnesis_created_by: perawatName,
    //   }

    //   // console.log(this.patientVisitData, 'data kunjungan pasien')
    //   // console.log(this.patientVisitDocument, 'dokumen kunjungan pasien')
    // },
    filterExamination(e) {
      const filterValue = e.target.value.toLowerCase()
      this.filteredDataSource = this.dataSource.filter((item) =>
        item.name.toLowerCase().includes(filterValue),
      )
    },
    handleChangeStatus(selectedStatus) {
      this.filteredTableData =
        selectedStatus !== 'Semua'
          ? this.tableData.filter((item) => item.status === selectedStatus)
          : [...this.filteredTableData]
    },
    handleDateChange(date) {
      if (!date) return
      const newDate = date.toISOString().split('T')[0]
      this.dateQuery = newDate
      // console.log(this.dateQuery, "tanggal");
      // this.getVisit()
    },
    handleAddExamination() {
      this.$router.push('/laborant/add')
    },
    goToDetail(record) {
      console.log(record, '<<<')
      this.$router.push(`/laborant/detail/${record.id}`)
    },
    async handleToggleDelete(id) {
      console.log(id)
      this.selectedDeleteId = id
      this.modalDeleteVisible = !this.modalDeleteVisible
    },
    async handleDelete() {
      try {
        await deleteExaminations('master', this.selectedDeleteId)
        this.fetchData() // Refresh data setelah penghapusan
        this.modalDeleteVisible = false
      } catch (error) {
        console.error('Error deleting data:', error)
      }
    },
    getStatusColor(status) {
      const statusColorMap = {
        'Menunggu Perawat': '#875BF7',
        'Menunggu Spesimen': '#EF6820',
        'Diperiksa Dokter': '#2E90FA',
        'Menunggu Pemeriksaan': '#2E90FA',
        'Selesai Diperiksa': '#16B364',
      }
      return statusColorMap[status]
    },
    getStatusBackgroundColor(status) {
      const statusColorMap = {
        'Menunggu Perawat': '#F3F1FF',
        'Menunggu Spesimen': '#FFF2E4',
        'Diperiksa Dokter': '#E1F1FF',
        'Menunggu Pemeriksaan': '#E1F1FF',
        'Selesai Diperiksa': '#E1FBEA',
      }
      return statusColorMap[status]
    },
  },
}
</script>

<style scoped>
.select-custom .ant-select-selector {
  padding-left: 12px !important;
  /* Sesuaikan padding sesuai kebutuhan */
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.header-text {
  font-size: 18px;
  text-align: center;
  color: #428a94;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.delete-button .icon-default {
  display: inline;
}

/* Hover icon disembunyikan secara default */
.delete-button .icon-hover {
  display: none;
}

/* Saat tombol di-hover */
.delete-button:hover .icon-default {
  display: none;
}

.delete-button:hover .icon-hover {
  display: inline;
}

/* Default icon terlihat */
.edit-button .icon-default {
  display: inline;
}

/* Hover icon disembunyikan secara default */
.edit-button .icon-hover {
  display: none;
}

/* Saat tombol di-hover */
.edit-button:hover .icon-default {
  display: none;
}

.edit-button:hover .icon-hover {
  display: inline;
}
</style>
